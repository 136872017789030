import '../styles/fonts/fonts.css'
import '../styles/styles.scss'
import '../styles/styles.less'
import '../styles/styles.pcss'


import $ from "expose-loader?exposes=$,jQuery!jquery";
import "../third-party/mapsvg/js/jquery.mousewheel.min.js"
import "../third-party/mapsvg/js/jquery.nanoscroller.min.js"
// import "jquery-migrate";


import "../third-party/jQuery-Validation-Engine-master/js/jquery.validationEngine.min";
import "../third-party/jQuery-Validation-Engine-master/js/languages/jquery.validationEngine-de.js";
import "../third-party/jquery.gridster";
import "../third-party/qTip/jquery.qtip.min";

import 'bootstrap';
import 'lightbox2';

import 'select2';
$.fn.select2.defaults.set("theme", "bootstrap4");

import 'lightslider';
import 'lazyload';
import 'owl.carousel';

import 'bootstrap-fileinput';
import 'bootstrap-fileinput/themes/fa5/theme.min.js';
import 'bootstrap-fileinput/js/locales/de.js';

import IBAN from "iban";
window.IBAN = IBAN;

import "./modules/menus.js";

import "../third-party/plugins";
import "../third-party/bs-select";

import "../third-party/lib";
import "../third-party/selectsplitter";
import "../third-party/bootstrap-autocomplete/typeahead";


window.getMapSVG5 = async function() {
     const [mapsvg, Raphael] = await Promise.all([
          import("../third-party/svgmap/js/mapsvg.min.5.7.0"),
          import("raphael")     
     ])
     window.Raphael = Raphael.default
}



window.getMapSVG8 = async function() {
     const tinycolor = await import("../third-party/mapsvg/js/mapsvg")
     /* tinycolor wird wegen der UMD als default Exportiert, statt in den window Scope übergeben. Deshalb machen wir hier das mal händisch nach. */
     window.MapSVG.tinycolor = tinycolor.default;
}


window.loadHomepage = () => import("./modules/Homepage")
//window.loadRaphael = () => import("./modules/Raphael")