/****************************************************************************************/
/* hier definierte functions sind nicht mit onClick aufrufbar, da dann einen jQuery     */
/* Selector setzen und weitere Aktionen ausführen								   */
/****************************************************************************************/

import $ from "jquery"

/*main menu*/

$('#ToggleTopNav').click(function(){
	$('#mobilemenu').toggle();
	$('#searchblockmobil').toggle();
	if ($('.mobi').is(':visible')) {
		if ($('#mobilemenu').is(':visible')) {
			//wenn VF-Menue noch nicht getriggert
			$('#vf-anchor').hide();
		}else{
			$('#vf-anchor').show();
		}
	}
})


$('.nav1Button').mouseover(function(){
	$('#mainpanel').show();
	$('#globemenu').hide();
	//$('#megamenum').hide();
	$('.submenu').hide();

	var id = $(this).attr('rel').replace('nav1','');
	$('#submenu'+id).show();
	$('#submenuicons'+id).show();
	
	var groundpos = $('#navleftpt').position().left;

	var pos = $(this).position().left - groundpos - parseInt(5);
	
	if(!$(this).hasClass('nav1panel')){
		$('#submenu'+id).css('left',pos);
	}
	if (!$('#submenu'+id).length){
		$('#mainpanel').hide();
	}
});

$('.nav2').mouseover(function(){

	if(!$(this).hasClass('salesunits')){
		
		$('.subsubmenu').hide();	

		var id = $(this).attr('rel').replace('nav2','');
console.log('navid: ' + id);
		$('#subsubmenu'+id).show();

		//var pos = $($(this)).width() + 20;

		//$('#subsubmenu'+id).css('left',pos);
		
	}	
});

$('#content').mouseover(function(){
	$('.submenu').hide();
	$('.subsubmenu').hide();
	$('#submenuicons').hide();
});

$('#mainpanel').mouseleave(function(){
	$('#mainpanel').hide();
	$('.subsubmenu').hide();
});


$('#globe').click(function(){
	$('#mainpanel').hide();
});

/****************************************************************************************/
/*mobile menu*/

$('.mobinav1Button').click(function(){
	$('.mobilesubmenu').hide();

	var id = $(this).attr('rel').replace('nav1','');
	$('#mobilesubmenu'+id).toggle();
});

$('.mobilenav2').click(function(){
	$('.mobilesubsubmenu').hide();

	var id = $(this).attr('rel').replace('mobilenav2','');
	$('#mobilesubsubmenu'+id).toggle();

});

/****************************************************************************************/
/*mega menu*/

$('#icon-su-blue').click(function(){
	$('.dropdown-menu').toggle();
});

$('#icon-su-white').click(function(){
	$('.dropdown-menu').toggle();
});

$('.dropdown-menu').click(function(){
	$(this).css('display','none');
});

//////

$(document).ready(function() {

	$('#searchblockmobil').hide();
	
	$(".icon-su-blue").click(function() {						 
		$('.dropdown-menu', this).stop().fadeIn("fast");
	},function() { 
		$('.dropdown-menu', this).stop().fadeOut("fast");
	});		
	
	$(".icon-su-white").click(function() {						 
		$('.dropdown-menu', this).stop().fadeIn("fast");
	},function() { 
		$('.dropdown-menu', this).stop().fadeOut("fast");
	});		

    $('#q').typeahead({
		minLength: 1,
		highlight: true,
		source: function (query, result) {
			$.ajax({
				url: "/services/autocomplete.cfm?f=terms&q=" + encodeURIComponent(query),
				dataType: "json",
				success: function (data) {
					result($.map(data, function (item) {
						return item;
					}));
				}
			});
		}
	});

	$('#q,#qmobil').keyup(function(e) {
		switch(e.keyCode) {
			case 38: // up arrow
				e.preventDefault();
				$('ul.typeahead li').css('background-color','transparent');
				$('ul.typeahead li.active').css('background-color','#F8F9FA');
				$(this).val($('ul.typeahead li.active a.dropdown-item').text());
				break;		
			case 40: // down arrow
				e.preventDefault();
				$('ul.typeahead li').css('background-color','transparent');
				$('ul.typeahead li.active').css('background-color','#F8F9FA');
				$(this).val($('ul.typeahead li.active a.dropdown-item').text());
				break;
			case 13: 
				document.location = '/finder?q=' + $(this).val();	
		}
	});

	$("#qmobil").typeahead({
		minLength: 1,
		source: function (query, result) {
			$.ajax({
				url: "/services/autocomplete.cfm?f=terms&q=" + encodeURIComponent(query),
				dataType: "json",
				success: function (data) {
					result($.map(data, function (item) {
						return item;
					}));
				}
			});
		}
	});

	$('.mobimega').click(function(){
		$('.dropdown-menu').toggle();
	});		

	$('#searchform').click(function(){
		$('.dropdown-menu').hide();
	});

	$('#searchformmobil').click(function(){
		$('.dropdown-menu').hide();
	});

	$('#lupemobil').click(function(){	

		$('#searchmobil').toggle();

		var checklupe = $('#searchmobil').is(':hidden');

		if( checklupe == true ) {
			window.location.href = "/finder?q=" + $("#qmobil").val();	
		} 

	});

	$('#lupe').click(function(){
		window.location.href = "/finder?q=" + $("#q").val();
	});

});
